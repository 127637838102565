
import { ZoneTypes, SiteZone } from "@/store/modules/zone/zone.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const zoneX = namespace(ZoneTypes.MODULE);

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddZone: () => import("@/components/zone/AddZone.vue"),
    EditZone: () => import("@/components/zone/EditZone.vue"),
    DeleteZone: () => import("@/components/zone/DeleteZone.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class ZonesView extends Vue {
  @zoneX.Mutation(ZoneTypes.SET_ADD_ZONE_DIALOG)
  public setAddZone!: (addZone: boolean) => void;

  @zoneX.Mutation(ZoneTypes.SET_UPDATE_ZONE_DIALOG)
  public setUpdateZone!: (updateZone: boolean) => void;

  @zoneX.Mutation(ZoneTypes.SET_DELETE_ZONE_DIALOG)
  public setDeleteZone!: (deleteZone: boolean) => void;

  @zoneX.Mutation(ZoneTypes.SET_TICKET_ZONE_DATA)
  public setSiteZoneData!: (ticketZone: SiteZone) => void;

  @zoneX.Action(ZoneTypes.LOAD_ZONES)
  public getSiteZones!: (pagination: Pagination) => void;

  @zoneX.State(ZoneTypes.ZONES)
  public ticketZones!: SiteZone[];

  @zoneX.State(ZoneTypes.LOADING_ZONES_STATE)
  public loadingZones!: boolean;

  @zoneX.State("addZoneRef")
  public addZoneRef!: number;

  openAddZoneDialog(): void {
    this.setAddZone(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "DESCRIPTION",
      value: "description",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deleteZone(item: SiteZone): void {
    this.setDeleteZone(true);
    this.setSiteZoneData(item);
  }

  editZone(item: SiteZone): void {
    this.setUpdateZone(true);
    this.setSiteZoneData(item);
  }

  mounted(): void {
    this.getSiteZones({ page: 1, limit: 10 });
  }
}
